import React from 'react'
import "./Footer.css"
import Github from "../../assets/github.png"
import LinkedIn from "../../assets/linkedin.png"
import Instagram from "../../assets/instagram.png"
import Logo from "../../assets/logo.png"
const Footer = () => {
  return (
 <div className="footer-container">
            <hr />
            <div className="footer">
                <div className='social-links'>
                    <a href="https://www.instagram.com/t0ny_dj/"><img src={Instagram}alt="" /></a>
                    <a href="https://www.instagram.com/t0ny_dj/"><img src={Github} alt="" /></a>
                    <a href="https://www.linkedin.com/in/ahmed-mohamedsalih-b2643122a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BYqDirjzhTMCkjq8kdDIOVg%3D%3D" > <img src={LinkedIn} alt="" /></a>  
                    
                </div>
                <div className="logo-f">
                <img src={Logo} alt="" />
                </div>
                
           </div>  
        
            <div className="blur blur-f-1"></div>
            <div className="blur blur-f-2"></div>

 </div>
  )
}

export default Footer