import React from 'react'
import './Programs.css'
import RightArrow from "../../assets/rightArrow.png"
import {programsData, ProgramsData} from "../../data/programsData"
const Programs = () => {
  return (
    <div className='programs' id='programs'>
        {/*header*/}
        <div className='programs-header'>
            <span className='stroke-text'>Explore our</span>
            <span>programs </span>
            <span className='stroke-text'>to shape you</span>
        </div>
       {/*programs category */}
        <div className='programs-category'>
           {programsData.map((Program) => (
            <div className="category">
                {Program.image}
                <span>{Program.heading}</span>
                <span>{Program.details}</span>
                <div className="join-now"><span>join Now</span> <img src={RightArrow} /></div>
            </div>

           ))

            }

        </div>
    </div>
  )
}

export default Programs