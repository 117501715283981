import './App.css';
import Hero from './components/hero/Hero';
import Programs from './components/Programs/Programs';
import Reasons from './components/Reasons/Reasons';
import Plans from './components/Plans/Plans';
import Teastimonials from './components/Teastimonials/Teastimonials';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer';
function App() { 
  return (
    <div className="App">
        <Hero />
        <Programs />
        <Reasons />
        <Plans />
        <Teastimonials />
        <Join />
        <Footer />
    </div>
  );
}

export default App;
