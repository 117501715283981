import React ,{useRef} from 'react'
import "./Join.css"
import emailjs from '@emailjs/browser'
const Join = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_g8uaqra', 'template_fmz83zx', form.current, '9fP9ok-dToDqj7x84')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        };
  return (
    <div className='join' id='join-us'>
         <div className='left-j'>
             <hr />
             <div>
                <span className='stroke-text'>READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
            </div>
         </div>
         <div className="right-j">
             <form ref={form} action="" className='email-contianr' onSubmit={sendEmail}>
              <input type="email" name='user_name' placeholder='Enter Your Email Addres '/>
              <button className='btn btn-join'>Join Now</button>
             </form>
         </div>
    </div>
  )
}

export default Join